body,
html {
    /* height: 100%; */
    margin: 0;
    padding: 0;

}

body:not(.bodyLogin) {
    height: calc(100% - 89px);
    margin-top: 65px !important;
    padding-top: 24px !important;
    background-color: #F5F4F6 !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
