div#container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    height: 100vh;
    justify-content: center;
    gap: 70px;
    width: 100%;
}

.controlaLogo {
    width: 100%;
    max-width: 300px;
}

.divControlaLogo {
    text-align: center;
    margin-bottom: 2rem;
}

.loginForm {
    display: flex;
    flex-direction: column;
    gap: 30px;

}

a#forgotPassword {
    color: #8B8BD8;
    text-decoration: none;
    font-size: 15;
}

.loginButtons {
    display: flex;
    flex-direction: row;
    gap: 90px;
}

.navBar {
    background: linear-gradient(to top, #0C2464, #091641);
    width: 70%;
    height: 100vh;
    display: flex;
    justify-content: right;
    align-items: center;
}

.elements {
    display: flex;
    flex-direction: column;
    /* width: 30%; */
    justify-content: right;

}

.showPassword:hover {
    cursor: pointer;
}